<template>
  <!-- 产品管理 -->
  <div>
    <div class="flex">
      <div>
        <el-button type="primary" @click="addClick" class="mr10"
          >新增</el-button
        >
        <el-dropdown>
          <el-button type="primary" class="mr10">导入</el-button>

          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="exportClick"
              >导入Excel</el-dropdown-item
            >
            <a
              href="/产品模板.xls"
              download="产品模板.xls"
              style="
                color: #606266;
                display: block;
                text-align: center;
                font-size: 14px;
                text-decoration-line: none;
              "
              >下载模板</a
            >
          </el-dropdown-menu>
        </el-dropdown>
        <el-button type="primary" @click="productexport">导出</el-button>
        <el-button type="primary" @click="picUpload">批量上传图片</el-button>
        <el-button type="danger" @click="batchDetele">批量删除</el-button>
        <el-button type="primary" @click="batchGrounding(1)">批量上架</el-button>
        <el-button type="warning" @click="batchGrounding(0)">批量下架</el-button>
        <el-button type="warning" @click="labelClick">标签维护</el-button>
      </div>
    </div>
    <div>
      <el-form :model="listQuery" :inline="true">
        
        <el-form-item label="是否为特价产品：">
          <el-select v-model="listQuery.isSpecialPrice" clearable style="width:120px">
            <el-option label="是" :value="1"></el-option>
            <el-option label="否" :value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态：">
          <el-select v-model="listQuery.productStatus" clearable style="width:120px">
            <el-option label="上架" :value="1"></el-option>
            <el-option label="下架" :value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="类别：">
          <el-select v-model="listQuery.target" clearable style="width:220px">
            <el-option
              v-for="(item, index) in typeList"
              :key="index"
              :label="item.target"
              :value="item.target"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="listQuery.name"
            placeholder="请输入主标题/小标题/封面图编码"
            style="width: 240px"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search">搜索</el-button>
          <el-button @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="tableData"
      style="width: 100%"
      @select="onSelect"
      @select-all="onSelectAll"
      row-key="rowKey"
      ref="multipleTable">
      <el-table-column type="selection" width="45"></el-table-column>
      <el-table-column type="index" label="序号" width=""></el-table-column>
      <el-table-column prop="productImgUrl" label="图片" width="">
        <template v-slot="scope">
          <el-image 
          v-if="scope.row.productImgUrl"
            :src="
              projectName +
              '/common/backstage/file/fileDownLoad?filePath=' +
              encodeURIComponent(scope.row.productImgUrl)
            "
            class="image"
            :preview-src-list="[
              projectName +
              '/common/backstage/file/fileDownLoad?filePath=' +
              encodeURIComponent(scope.row.productImgUrl)]">
          </el-image>
          <!-- <img
            v-if="scope.row.productImgUrl"
            :src="
              projectName +
              '/common/backstage/file/fileDownLoad?filePath=' +
              encodeURIComponent(scope.row.productImgUrl)
            "
            class="image"
          /> -->
        </template>
      </el-table-column>
      <el-table-column
        prop="coverCartography"
        label="封面图编码"
        width=""
      ></el-table-column>
      <!-- <el-table-column prop="number" label="主图编码" width=""></el-table-column> -->
      <el-table-column prop="target" label="类别" width=""></el-table-column>
      <!-- <el-table-column prop="target" label="类别" width=""></el-table-column> -->
      <el-table-column prop="name" label="主标题" width=""></el-table-column>
      <el-table-column
        prop="subtitle"
        label="小标题"
        width=""
      ></el-table-column>
      <!-- <el-table-column prop="placeName" label="空间" width=""></el-table-column> -->
      <el-table-column
        prop="price"
        label="价格(元)"
        width="100"
      ></el-table-column>
      <el-table-column
        prop="discountPrice"
        label="横线价格(元)"
        width="140"
      ></el-table-column>
      <el-table-column prop="date" label="是否为特价产品" width="">
        <template v-slot="scope">
          <span>{{ scope.row.isSpecial == "0" ? "否" : "是" }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="lable" label="标签"></el-table-column>
      <el-table-column prop="productStatus" label="状态" width="100">
        <template v-slot="scope">
          <span
            :class="scope.row.productStatus == 0 ? 'yellowcolor' : 'bluecolor'"
            >{{ scope.row.productStatus == 1 ? "上架" : "下架" }}</span
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="updateTimeRes"
        label="更新时间"
        width="170"
      ></el-table-column>
      <el-table-column prop="date" label="操作" width="220">
        <template v-slot="scope">
          <span v-if="scope.row.isTop == 1" style="margin-right: 10px"
            >置顶</span
          >
          <el-button type="text" @click="toppingClick(scope.row)" v-else
            >置顶</el-button
          >
          <el-button type="text" @click="editClick(scope.row)">编辑</el-button>
          <el-button type="text" @click="viewClick(scope.row)">查看</el-button>
          <el-button
            type="text"
            @click="grounding(scope.row)"
            :class="scope.row.productStatus == 1 ? 'yellowcolor' : ''"
            >{{ scope.row.productStatus == 0 ? "上架" : "下架" }}</el-button
          >
          <el-button
            type="text"
            class="redColor"
            @click="deleteClick(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="footer">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="listQuery.current"
        :page-size="listQuery.size"
        layout="total, prev, pager, next, jumper"
        :total="listQuery.total"
        ref="pagination"
      >
      </el-pagination>
    </div>

    <el-dialog
      title="数据导入"
      :visible.sync="rechargeVisible"
      :show-close="false"
      :close-on-click-modal="false"
      @closed="closed"
      width="20%">
      <div>
        <input type="file" @click="inputClick" @change="fileSucess" />
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="rechargeVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="importClick"
          :loading="loginBtnDisabled"
          :disabled="loginBtnDisabled"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <!-- 批量删除 -->
    <el-dialog title="批量删除" :visible.sync="dialogVisible" width="320px">
      <div class="dialog-con" v-if="isJudge == 1">
        有未下架的产品，不能删除！
      </div>
      <div class="dialog-con" v-if="isJudge == 2">
        您已勾选{{ this.checkData.length }}条数据，确定删除？
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="deleteArr">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 批量上下架 -->
    <el-dialog
      :title="titleName"
      :close-on-click-modal="false"
      :visible.sync="upperLowerDialogVisible"
      width="350px">
      <div
        class="dialog-con"
        v-if="titleName == '批量上架' && isUpperLower == '1'"
      >
        勾选数据中有“下架”状态的产品，请确认！
      </div>
      <div
        class="dialog-con"
        v-if="titleName == '批量下架' && isUpperLower == '1'"
      >
        勾选数据中有“上架”状态的产品，请确认！
      </div>
      <div class="dialog-con" v-if="isUpperLower == '2'">
        您已勾选{{ this.checkData.length }}条数据，确定进行{{
          titleName == "批量上架" ? "上架" : "下架"
        }}操作？
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="upperLowerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="upperLowerArr">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 标签维护 -->
    <el-dialog
      title="标签维护"
      :close-on-click-modal="false"
      :visible.sync="labeldialogVisible"
      width="350px">
      <!-- <span>这是一段信息</span> -->
      <div class="label-class">确定将您勾选的{{ this.checkData.length }}条数据，标签批量修改为：</div>
      <el-input v-model="label" placeholder="请输入标签" style="width:250px"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="labeldialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="labelSure">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 批量上传图片 文件夹形式 -->

    
    <el-dialog
      title="批量上传图片"
      :visible.sync="picDialogVisible"
      width="400px">
        <el-upload
          ref="upload"
          class="upload-demo"
          :action="projectName + '/common/backstage/file/fileupload'"
          :headers="headers"
          :show-file-list="false"
          :on-success="handleAvatarSuccess">
          <div>
            <el-button size="small" type="primary">点击上传</el-button>
          </div>
        </el-upload>

        <div class="uploadSuccess" v-if="fileName"> 
          <div >{{fileName}}</div>
          <div class="misstake" @click="missClick">×</div>
        </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="picDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="fileUrlClick">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  page,
  productdelete,
  categorylist,
  product,
  isProductStatus,
  productTop,
  branchDeleteArr,
  branchuUpperLower,
  productexport,
  labelchange,
  updateImg
} from "../../api/index";
import { projectName } from "../../util/config";
export default {
  data() {
    return {
      headers: {sessionId: sessionStorage.getItem("access_token")},
      label:'',
      labeldialogVisible: false,
      picDialogVisible: false,
      rechargeVisible: false,
      loginBtnDisabled: false,
      dialogVisible: false,
      upperLowerDialogVisible: false, //批量上下架
      totalNum: 10,
      uploadObj: {
        file: "",
      },
      listQuery: {
        name: "",
        target: "",
        isSpecialPrice: null,
        size: 10,
        current: 1,
        total: 0,
      },
      projectName: projectName,
      typeList: [
        { name: "家居灯", value: "0" },
        { name: "无主灯/智能灯", value: "1" },
        { name: "商照", value: "2" },
        { name: "户外", value: "3" },
        { name: "光源", value: "4" },
        { name: "开关插座", value: "5" },
      ],
      tableData: [],
      printData: {}, //单选
      checkData: [], //勾选的数据
      dataArr: [], //用来判断数据是否为上架或下架
      isJudge: "",
      titleName: "", //上下架名称
      isUpperLower: "",
      fileUrl:'',//批量上传图片
      fileName:'',
    };
  },
  mounted() {
    if (this.$route.query.current) {
      this.listQuery.current = this.$route.query.current - 0;
      this.$nextTick(() => {
        this.$refs.pagination.internalCurrentPage = this.listQuery.current;
      });
    }
    this.getTableData();
    this.getTypeData();
  },
  methods: {
    // 单个勾选 productStatus 0下架，1上架
    onSelect(selection, row) {
      // console.log(selection,"selectionselection")
      this.dataArr = selection;
      for (let i = 0; i < this.dataArr.length; i++) {
        if (this.dataArr[i].productStatus == 1) {
          this.isJudge = 1;
          break;
        } else {
          this.isJudge = 2;
        }
      }
      if (this.printData[row.productId]) {
        delete this.printData[row.productId];
      } else {
        this.printData[row.productId] = row;
      }
      // console.log(this.printData,"勾选改变之前的数据")
      this.checkData = Object.keys(this.printData);
      // console.log(this.checkData,"this.checkData处理之后的数据")
    },
    // 全选 productStatus 0下架，1上架
    onSelectAll(selection) {
      // console.log(selection,"selectionselection")
      this.dataArr = selection;
      for (let i = 0; i < this.dataArr.length; i++) {
        if (this.dataArr[i].productStatus == 1) {
          this.isJudge = 1;
          break;
        } else {
          this.isJudge = 2;
        }
      }
      if (selection.length) {
        selection.forEach((row) => {
          if (!this.printData[row.productId]) {
            this.printData[row.productId] = row;
          }
        });
      } else {
        this.tableData.forEach((row) => {
          delete this.printData[row.productId];
        });
      }
      // console.log(this.printData,"勾选改变之前的数据")
      this.checkData = Object.keys(this.printData);

      // console.log(this.checkData,"this.printData22222")
    },
    checkRows() {
      var that = this;
      const keys = Object.keys(this.printData);
      const rows = this.tableData.filter((row) => {
        return keys.includes(String(row.productId));
      });
      rows.forEach((row) => {
        that.$nextTick(function () {
          that.$refs.multipleTable.toggleRowSelection(row, true);
        });
      });
    },
    // 导出数据
    productexport() {
      this.search();
      let that = this;
      let numName = '';
      if(this.checkData.length>0) {
        numName = '您已勾选' + this.checkData.length + '条数据，'
      } else {
        numName = ''
      }
      
      this.$confirm(numName+"确定导出数据吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let fileName = "产品管理.xls";
          let data = {
            ...that.listQuery,
            productIdList: this.checkData
          }
          productexport(data).then((res) => {
            let blob = new Blob([res.data], {
              type: "application/pdf;charset-UTF-8",
            });
            if (navigator.msSaveOrOpenBlob) {
              navigator.msSaveOrOpenBlob(blob, fileName);
            } else {
              const blobUrl = URL.createObjectURL(blob);
              let a = document.createElement("a");
              a.href = blobUrl;
              a.download = fileName;
              a.style.display = "none";
              a.click();
              URL.revokeObjectURL(blobUrl);
            }
            this.getTableData()
            this.checkData = [];
            this.printData = {};
            this.dataArr = [];

          });
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "已取消导出",
          // });
        });
    },
    // 批量删除
    batchDetele() {
      if (this.checkData.length <= 0) {
        this.$message.warning("请先勾选数据！");
      } else {
        this.dialogVisible = true;
      }
    },
    //删除确定
    deleteArr() {
      branchDeleteArr({ productIds: this.checkData }).then((res) => {
        if (res.code == 1) {
          this.$message.success("删除成功！");
          this.dialogVisible = false;
          this.checkData = [];
          this.printData = {};
          this.dataArr = [];
          this.getTableData();
        }
      });
    },
    //批量上架1 下架0
    batchGrounding(num) {
      // console.log(this.dataArr,"dataArrdataArrdataArr")
      if (num == 1) {
        this.titleName = "批量上架";
      } else {
        this.titleName = "批量下架";
      }
      for (let i = 0; i < this.dataArr.length; i++) {
        if (num == 1) {
          if (this.dataArr[i].productStatus == 1) {
            this.isUpperLower = "1";
            break;
          } else {
            this.isUpperLower = "2";
          }
        } else if (num == 0) {
          if (this.dataArr[i].productStatus == 0) {
            this.isUpperLower = "1";
            break;
          } else {
            this.isUpperLower = "2";
          }
        }
      }
      if (this.checkData.length <= 0) {
        this.$message.warning("请先勾选数据！");
      } else {
        this.upperLowerDialogVisible = true;
      }
    },
    upperLowerArr() {
      let type = null;
      if (this.titleName == "批量上架") {
        type = 1;
      } else {
        type = 0;
      }
      let data = {
        type: type,
        productIds: this.checkData,
      };
      branchuUpperLower(data).then((res) => {
        if (res.code == 1) {
          this.$message.success("操作成功！");
          this.upperLowerDialogVisible = false;
          this.checkData = [];
          this.printData = {};
          this.dataArr = [];
          this.getTableData();
        }
      });
    },
    toppingClick(row) {
      this.$confirm("是否置顶该产品？", "提示", {
        confirmButtonText: "确定",
        concelButtonText: "取消",
        type: "warning",
      }).then(() => {
        productTop({ productId: row.productId }).then((res) => {
          if (res.code == 1) {
            this.$message({
              type: "success",
              message: "操作成功！",
            });
            this.getTableData();
          }
        });
      });
    },
    getTypeData() {
      let data = {
        current: 1,
        size: 1000,
        type: 0,
      };
      categorylist(data).then((res) => {
        if (res.code == 1) {
          this.typeList = res.data.records || [];
        }
      });
    },
    getTableData() {
      let data = {
        ...this.listQuery,
      };
      page(data).then((res) => {
        if (res.code == 1) {
          this.tableData = res.data.records || [];
          this.listQuery.total = res.data.total;
          this.checkRows();
        }
      });
    },
    addClick() {
      this.$router.push({ name: "productAdd" });
    },
    search() {
      this.listQuery.current = 1;
      this.getTableData();
    },
    reset() {
      this.listQuery = {
        name: "",
        target: "",
        isSpecialPrice: null,
        size: 10,
        current: 1,
        total: 0,
      };
      this.getTableData();
    },
    handleCurrentChange(current) {
      this.listQuery.current = current;
      this.getTableData();
    },
    editClick(row) {
      // let href = `/#/home/productEdit?productId=${row.productId}&isEdit=${1}`
      // window.open(href, "_blank");
      this.$router.push({
        name: "productEdit",
        query: {
          productId: row.productId,
          isEdit: 1,
          current: this.listQuery.current,
        },
      });
    },
    viewClick(row) {
      // productView
      // window.location.href = `/home/productView?productId=${row.productId}&isView=${true}`
      // let href = `/#/home/productView?productId=${row.productId}&isEdit=${true}`
      // window.open(href, "_blank");
      this.$router.push({
        path: "/home/productView",
        query: {
          productId: row.productId,
          isView: true,
          current: this.listQuery.current,
        },
      });
    },
    // 是否上架 productStatus 0下架 1上架
    grounding(row) {
      let productStatus = row.productStatus == 0 ? "上架" : "下架";
      this.$confirm(`是否${productStatus}该产品？`, "提示", {
        confirmButtonText: "确定",
        concelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let productStatus = row.productStatus == 0 ? 1 : 0;
        let data = {
          productId: row.productId,
          productStatus: productStatus,
        };
        // console.log(data,"jjjjjj")
        isProductStatus(data).then((res) => {
          if (res.code == 1) {
            this.$message({
              type: "success",
              message: "操作成功！",
            });
            this.getTableData();
          }
        });
      });
    },
    deleteClick(row) {
      this.$confirm("是否确认删除此产品？", "提示", {
        confirmButtonText: "确定",
        concelButtonText: "取消",
        type: "warning",
      }).then(() => {
        productdelete({ id: row.productId }).then((res) => {
          if (res.code == 1) {
            this.$message({
              type: "success",
              message: "删除成功！",
            });
            this.getTableData();
          }
        });
      });
    },

    // 导入数据
    exportClick() {
      this.rechargeVisible = true;
    },
    closed() {
      history.go(0);
    },
    // 点击文件的时候 先清空
    inputClick(event) {
      // console.log(event,"event1123")
      event.target.value = "";
      this.uploadObj.file = "";
    },
    // 导入change事件
    fileSucess(event) {
      // console.log(event,"eventeventevent")
      this.uploadObj.file = event.target.files[0];

      // console.log(this.uploadObj.file,"this.uploadObj.filethis.uploadObj.file")
    },
    importClick() {
      if (!this.uploadObj.file) {
        this.$message.info("请选择文件");
        return;
      }
      let files = new FormData();
      files.append("file", this.uploadObj.file);
      let data = {
        identification: this.identification,
      };
      // console.log(data,"ddddd")
      this.loginBtnDisabled = true;
      product(files)
        .then((res) => {
          if (res.code == 1) {
            this.$message.success("导入成功！");
            this.dialogVisible = false;
            history.go(0);
          } else {
            // let msg = res.message
            // alert(msg)
            this.loginBtnDisabled = false;
          }
        })
        .catch(() => {
      this.loginBtnDisabled = false;
          this.$message.error(res.msg);
        });
    },

    // 标签维护
    labelClick() {
      if (this.checkData.length <= 0) {
        this.$message.warning("请先勾选数据！");
      } else {
        this.labeldialogVisible = true;
      }

      // console.log(this.checkData,"标签维护")
    },
    // 标签确定
    labelSure() {
      labelchange({productIdList:this.checkData,lable:this.label}).then(res=>{
        if(res.code==1) {
          this.label = "";
          this.checkData = [];
          this.printData = {};
          this.$message.success("操作成功！");
          this.labeldialogVisible = false;
          this.getTableData()
        }
      })
    },


    picUpload() {
      this.picDialogVisible = true;
      this.fileName = '';
      this.fileUrl = ''
    },
    handleAvatarSuccess(res, file) {
      console.log(res,file,"handleAvatarSuccess")
      // this.form.image = res.data.nginxFileReadPath
      if(res.code== 1) {
        this.fileUrl = res.data.nginxFileReadPath
        this.fileName = res.data.originalFilename
      }
    },
    missClick() {
      this.fileName = '';
      this.fileUrl = ''
    },
    fileUrlClick() {
      if(this.fileUrl == '') {
        return this.$message.error("请上传文件！")
      }
        updateImg({url:this.fileUrl}).then(res=>{
          if(res.code==1) {
            this.getTableData()
            this.$message.success("操作成功！");
            this.picDialogVisible = false
          }
        })
    },
    // beforeAvatarUpload(file) {},
    // handlePreview(file) {},
    // handleRemove(file) {},
    // beforeRemove(file) {}
  },
};
</script>

<style lang="less" scoped>
.flex {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}
.mr10 {
  margin-right: 10px;
}
.footer {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}
.image {
  width: 120px;
  height: 90px;
}
.redColor {
  color: red;
}
.yellowcolor {
  color: #ffb129;
}
.dialog-con {
  text-align: center;
  color: red;
  font-size: 15px;
}
.bluecolor {
  color: #198cff;
}

.label-class {
  font-size: 14px;
  color: red;
  margin-bottom: 10px;
}

.uploadSuccess {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 300px;
  margin-top: 10px;
  padding: 5px;
  .misstake {
    margin-left: 30px;
    font-size: 16px;
    cursor: pointer;
  }
}
.uploadSuccess:hover {
  background: #F5F7FA;
}
/deep/.el-upload {
  text-align: left;
}
</style>